import React, { useEffect, useRef, useState } from 'react';
import Carousel, { generateDots } from 'my-react-carousel';
import Image from '@jetshop/ui/Image';
import Styled from './Style';
import CTALink from '../../components/ui/Link';
import BannerLink from '../../components/ui/BannerLink';
import useChildren from '../../hooks/useChildren';
import { Below } from '@jetshop/ui/Breakpoints';

const Dot = ({ isActive, onClick }) => (
  <Styled.DotButton onClick={onClick} isActive={isActive} />
);

const HeroBlock = props => {
  const cleanedProps = useChildren(props);
  const heroItems = cleanedProps['heroItem'];
  const carousel = useRef(null);
  const [timeoutId, setTimeoutId] = useState();

  useEffect(() => {
    setTimeout(() => {
      let timeoutId = setTimeout(() => {
        if (carousel?.current?.next) {
          carousel.current.next();
        }
      }, 5000);
      setTimeoutId(timeoutId);
    }, 0);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (!heroItems) return null;

  const clearCarousel = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  return (
    <Styled.HeroWrapper
      aria-label="hero carousel"
      onMouseEnter={() => clearCarousel()}
    >
      <Carousel
        ref={carousel}
        transitionDuration={500}
        slidesToShow={1}
        render={generateDots(({ slides, dots }) => (
          <>
            {slides}
            <Styled.DotContainer>
              {dots.map(({ index, isActive, onClick }) => (
                <Dot key={index} isActive={isActive} onClick={onClick} />
              ))}
            </Styled.DotContainer>
          </>
        ))}
      >
        {heroItems.map((heroItem, index) => {
          return (
            <Styled.HeroItemWrapper key={heroItem.key}>
              <Below breakpoint="sm">
                {matches =>
                  matches ? (
                    <Image
                      className="below-sm"
                      src={heroItem.backgroundImage}
                      aspect="5:4"
                      crop={true}
                      cover
                      sizes={[1, 1, 1, 1, 1, 1368]}
                    />
                  ) : (
                    <Image
                      className="above-sm"
                      src={heroItem.backgroundImage}
                      aspect="115:43"
                      crop={true}
                      cover
                      sizes={[1, 1, 1, 1, 1, 1368]}
                    />
                  )
                }
              </Below>
              {heroItem.removeImageBlur === false && (
                <Styled.FrostedGlass>
                  <div />
                </Styled.FrostedGlass>
              )}
              {(heroItem.externalLinkUrl || heroItem.internalLinkUrl) && (
                <BannerLink
                  externalLink={heroItem.externalLinkUrl}
                  internalLink={heroItem.internalLinkUrl}
                >
                  <Styled.ContentWrapper>
                    {heroItem.preHeader && (
                      <Styled.PreHeader level={4}>
                        {heroItem.preHeader}
                      </Styled.PreHeader>
                    )}
                    {heroItem.header && (
                      <Styled.Header level={1}>{heroItem.header}</Styled.Header>
                    )}
                    {heroItem.ctaLabel &&
                      (heroItem.externalLinkUrl ||
                        heroItem.internalLinkUrl) && (
                        <CTALink
                          externalLink={heroItem.externalLinkUrl}
                          internalLink={heroItem.internalLinkUrl}
                        >
                          {heroItem.ctaLabel}
                        </CTALink>
                      )}
                  </Styled.ContentWrapper>
                </BannerLink>
              )}
              {!heroItem.externalLinkUrl && !heroItem.internalLinkUrl && (
                <Styled.ContentWrapper>
                  {heroItem.preHeader && (
                    <Styled.PreHeader level={4}>
                      {heroItem.preHeader}
                    </Styled.PreHeader>
                  )}
                  {heroItem.header && (
                    <Styled.Header level={1}>{heroItem.header}</Styled.Header>
                  )}
                  {heroItem.ctaLabel &&
                    (heroItem.externalLinkUrl || heroItem.internalLinkUrl) && (
                      <CTALink
                        externalLink={heroItem.externalLinkUrl}
                        internalLink={heroItem.internalLinkUrl}
                      >
                        {heroItem.ctaLabel}
                      </CTALink>
                    )}
                </Styled.ContentWrapper>
              )}
            </Styled.HeroItemWrapper>
          );
        })}
      </Carousel>
    </Styled.HeroWrapper>
  );
};

export default HeroBlock;
